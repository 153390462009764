<template>
  <!-- view-toolbar -->
  <div class="view-toolbar-wrap" @click="activeStyle = null">
    <div @click="viewToolbarPressed('note')" class="view-toolbar-button">
      <img src="../../manual_assets/kf6images/01-toolbar-newwriting.png" alt="createNewNote" />
    </div>
    <div @click="viewToolbarPressed('attachment')" class="view-toolbar-button">
      <img src="../../manual_assets/kf6images/03-toolbar-attachment.png" alt="uploadAttachment" />
    </div>
    <div @click="viewToolbarPressed('hideName')" class="view-toolbar-button">
      <img src="../../manual_assets/aextimages/toolbar-hidden.png" alt="hide" v-if="!hiddenName" />
      <img src="../../manual_assets/aextimages/toolbar-display.png" alt="display" v-if="hiddenName" />
    </div>
    <div @click="viewToolbarPressed('drawing')" class="view-toolbar-button">
      <img src="../../manual_assets/kf6images/02-toolbar-newdrawing.png" alt="Drawing" />
    </div>
    <div @click="viewToolbarPressed('view')" class="view-toolbar-button">
      <img src="../../manual_assets/kf6images/04-toolbar-viewmanagement.png" alt="createView" />
    </div>
    <div @click="viewToolbarPressed('workspace')" class="view-toolbar-button">
      <img src="../../manual_assets/kf6images/07-toolbar-personalworkspace.png" alt="createView" />
    </div>
    <div @click="viewToolbarPressed('search')" class="view-toolbar-button">
      <img src="../../manual_assets/kf6images/08-toolbar-search.png" alt="searchVue" />
    </div>
    <div @click="viewToolbarPressed('kbdex')" class="view-toolbar-button">
      <img src="../../manual_assets/kf6images/analytics.png" alt="KBDeXVue" />
    </div>
    <div @click="viewToolbarPressed('chot')" class="view-toolbar-button">
      <img src="../../manual_assets/aextimages/toolbar-chat.png" alt="chot" />
    </div>
    <div @click="viewToolbarPressed('exit')" class="view-toolbar-button">
      <img src="../../manual_assets/kf6images/10-toolbar-exit.png" alt="exit" />
    </div>
  </div>

  <div class = "ScaleSetting">
    <div><label for="target">どんな基準で、Noteの大きさを変えますか？</label><br>
      <select v-model="selectedTarget" id="target">
        <option value="">選択してください</option>
        <option v-for="target in targets" :key="target.id" :value="target.name">{{ target.name }}</option><br>
      </select><p v-if="selectedTarget">Noteの大きさの基準{{ selectedTarget }}</p>
    </div>

    <div id = "app">
         視野のz軸1<input type="range" min = "1" max="5" step="0.01" id = "input_z" v-model = "input_z" value = "1">５
    </div>
  </div>

  <!-- 画像resize時のモーダル画面 -->
  <div class="modal-wrap" v-show="modal.target">
    <div class="modal-contents">
      <button class="modal-close-button" @click="modal.target = null">×</button>
      <input id="modal-contents-width" v-model="modal.resizeW" placeholder="width" type="number"
        @keydown.enter="modal.resizeH = modal.resizeW * modal.target.data.height / modal.target.data.width, modalEvent()" />×<input
        v-model="modal.resizeH" placeholder="height" type="number" @:keydown.enter="modalEvent()" />
      <button @click="modalEvent()">
        変更
      </button>
    </div>
  </div>

  <!-- context menu -->
  <vue-simple-context-menu-view id="context-menu" :elementId="'view'" :options="contextmenu" :ref="'vueSimpleContextMenu'"
    @option-clicked="contextMenuPressed"></vue-simple-context-menu-view>

  <!-- view画面全体 -->
  <div id="view-parent" @dragover.stop @drop.stop="drop"
    @mousedown="(activeStyle = null, linkingStyle = null, imgEnlarge = null)">

    <DraggableContainer :referenceLineVisible="false">

      <!-- view上のobjects -->
      <div v-for="link in gLinks" v-bind:key="link.to">

        <!-- ノート-->
        <Vue3DraggableResizable v-if="link._to.type === 'Note'" v-model:x="link.data.x" v-model:y="link.data.y"
          :active="false" :draggable="true" :resizable="false" @mousedown.passive.stop="screenX = $event.screenX,
            screenY = $event.screenY, activeStyle = link.to" @dblclick.passive="objectDoubleclicked(link)"
          @contextmenu.passive="showContextMenu($event, link)" class="object-style" :ref="'line/' + link.to" v-bind:class="{
            'new-object': link._to.time[1] >= currentTime,
            linking: linkingStyle === link.to || link.isfound,
            'active-style': activeStyle === link.to,
          }" style="z-index: 2;">
          <div v-if="link.hiddenLink !== true" @mouseup="mouseup($event, link)" :style="{ 'transform': 'scale('+link.Scale+','+link.Scale+')' }">
            <img :src="link._to.icon" alt="image" :ref="'line/' + link.to" />
            {{ link.cosSim }}{{ link._to.title }}<br />
            <span v-if="!hiddenName">{{ link._to.author.firstName + link._to.author.lastName}} <br />
              {{ link._to.time[0] }}
            </span>
          </div>
        </Vue3DraggableResizable>

        <!-- viewリンク -->
        <Vue3DraggableResizable v-else-if="link._to.type === 'View'" v-model:x="link.data.x" v-model:y="link.data.y"
          :active="false" :draggable="true" :resizable="false" @mousedown.passive.stop="screenX = $event.screenX,
            screenY = $event.screenY, activeStyle = link.to" @dblclick.passive="objectDoubleclicked(link)"
          @contextmenu.passive="showContextMenu($event, link)" class="object-style" :ref="'line/' + link.to" v-bind:class="{
            'new-object': link._to.time[1] >= currentTime,
            linking: linkingStyle === link.to,
            'active-style': activeStyle === link.to,
          }" style="z-index: 2;">
          <div @mouseup="mouseup($event, link)">
            <img :src="link._to.icon" alt="image" :ref="'line/' + link.to" />
            {{ link._to.title }}
          </div>
        </Vue3DraggableResizable>

        <!-- 画像 -->
        <Vue3DraggableResizable v-else-if="link.data.showInPlace === true
          " :initW="link.data.width" :initH="link.data.height" v-model:x="link.data.x" v-model:y="link.data.y"
          v-model:w="link.data.width" v-model:h="link.data.height" :active="false" :lockAspectRatio="lockAspectRatio"
          :draggable="true" :resizable="true" @mousedown.passive.stop="(screenX = $event.screenX,
            screenY = $event.screenY, activeStyle = link.to)" @dblclick.passive.stop="imgEnlarge = link.to"
          @mouseup="saveLink(link)" @contextmenu.passive="showContextMenu($event, link)" class="object-style"
          v-bind:class="{
            'new-object': link._to.time[1] >= currentTime,
            linking: linkingStyle === link.to,
            'active-style': activeStyle === link.to,
            'img-enlarge': imgEnlarge === link.to,
            'zindex0': link._to.type === 'Drawing',
          }" style="z-index: 1;">
          <div @mouseup.passive="mouseup($event, link)">
            <div v-if="link._to.type === 'Drawing'" class="Drawing" v-html="link._to.url" :ref="'line/' + link.to">
            </div>
            <div v-else v-html="link._to.url" :ref="'line/' + link.to">
            </div>
          </div>
        </Vue3DraggableResizable>

        <!-- Chot Session-->
        <Vue3DraggableResizable v-else-if="link._to.type === 'ChotSession'" v-model:x="link.data.x"
          v-model:y="link.data.y" :active="false" :draggable="true" @mousedown.passive.stop="screenX = $event.screenX,
            screenY = $event.screenY, activeStyle = link.to" @dblclick.passive="objectDoubleclicked(link)"
          @contextmenu.passive="showContextMenu($event, link)" class="object-style" :ref="'line/' + link.to" v-bind:class="{
            'new-object': link._to.time[1] >= currentTime,
            linking: linkingStyle === link.to,
            'active-style': activeStyle === link.to,
          }" style="z-index: 2;">
          <div @mouseup="mouseup($event, link)">
            <img :src="link._to.icon" style="width:16px; height:16px;" alt="image" :ref="'line/' + link.to" />
            {{ link._to.title }}<br />
            <span v-if="!hiddenName">{{ link._to.author.firstName + link._to.author.lastName }} <br />
              {{ link._to.time[0] }}
            </span>
          </div>
        </Vue3DraggableResizable>

        <!--その他の Attachment -->
        <Vue3DraggableResizable v-else v-model:x="link.data.x" v-model:y="link.data.y" :active="false" :draggable="true"
          @mousedown.passive.stop="screenX = $event.screenX,
            screenY = $event.screenY, activeStyle = link.to" @dblclick.passive="objectDoubleclicked(link)"
          @contextmenu.passive="showContextMenu($event, link)" class="object-style" :ref="'line/' + link.to" v-bind:class="{
            'new-object': link._to.time[1] >= currentTime,
            linking: linkingStyle === link.to,
            'active-style': activeStyle === link.to,
          }" style="z-index: 2;">
          <div @mouseup="mouseup($event, link)">
            <img :src="link._to.icon" alt="image" :ref="'line/' + link.to" />
            {{ link._to.title }}<br />
            <span v-if="!hiddenName">{{ link._to.author.firstName + link._to.author.lastName }} <br />
              {{ link._to.time[0] }}
            </span>
          </div>
        </Vue3DraggableResizable>
      </div>

      <!-- NoteWindow -->
      <noteVue v-for="note in gNotes" v-bind:key="note" :ref="('note/' + note.to)" :objectId="note.to" :note="note"
        :gLinks="gLinks" :hiddenName="hiddenName" :gCurrentView="gCurrentView" class="view-note-style"
        @mousedown="zindexStyle = note.to" v-bind:class="{ zindex: zindexStyle === note.to }" draggable=" false">
      </noteVue>

      <!-- チャットWindow -->
      <chatVue class="chat" v-for="chat in gChats" v-bind:key="chat" :ref="'chat/' + chat.to" :gCurrentView="gCurrentView"
        :root="chat" :gAuthorsTable="gAuthorsTable" :hiddenName="hiddenName" @chatLinking="chatLinking($event, toId)"
        style="z-index:3;position: fixed" draggable=" false"
        @mousedown="(selectedChatId = chat.to, zindexStyle = chat.to)" v-bind:class="{ zindex: zindexStyle === chat.to }">
      </chatVue>

    </DraggableContainer>

    <div class="iwindow-layer">
      <searchVue ref="searchview" :gKFdata="gKFdata">
      </searchVue>
      <KBDeXVue ref="kbdexview" :gKFdata="gKFdata" @openlink="objectDoubleclicked($event)">
      </KBDeXVue>
      <settingVue ref="settingview" :gKFdata="gKFdata">
      </settingVue>
      <chotVue ref="chotview" :gKFdata="gKFdata">
      </chotVue>
    </div>
  </div>

  <!-- windows -->

  <!-- 新規ノート作成画面 -->
  <noteVue v-for="(newNote, index) in gNewNotes" v-bind:key="newNote" :ref="('newNote/' + index)"
    :gCurrentView="gCurrentView" :gLinks="gLinks" class="view-note-style">
  </noteVue>

  <!-- createView画面 -->
  <CreateView ref="createview" class="view-note-style" :gCurrentView="gCurrentView">
  </CreateView>

  <!-- アップロード画面 -->
  <uploadAttachment ref="upload" class="view-note-style" :gCurrentView="gCurrentView">
  </uploadAttachment>

  <iframeVue class="view-note-style" ref="iframetool"></iframeVue>
</template>

<script>
/* eslint-disable */ //eslintのエラー制限
// @ is an alias to /src
import viewImage from "../../manual_assets/kf4images/icon-view.gif";
import attachmentImage from "../../manual_assets/kf4images/icon-attachment.gif";
import drawingImage from "../../manual_assets/kf4images/icon-drawing.gif";
import noteReadImage from "../../manual_assets/kf4images/icon-note-read-auth-.gif";
import noteUnreadImage from "../../manual_assets/kf4images/icon-note-unread-auth-.gif";
import chotImage from "../../manual_assets/aextimages/chot.png";
import noteVue from "../components/Note.vue";
import chatVue from "../components/Chat.vue";
import uploadAttachment from "../components/Upload.vue";
import iframeVue from "../components/Iframe.vue";
import CreateView from "../components/CreateView.vue";
import searchVue from "../components/Search.vue";
import KBDeXVue from "../components/KBDeX.vue";
import settingVue from "../components/Setting.vue";
import chotVue from "../components/ChotGPT.vue";

/* vue draggable resizable */
import Vue3DraggableResizable from "vue3-draggable-resizable";
import { DraggableContainer } from "vue3-draggable-resizable";

//default styles
import "vue3-draggable-resizable/dist/Vue3DraggableResizable.css";

/* vue simple context menu */
import "vue-3-simple-context-menu/dist/vue-simple-context-menu.css";
import VueSimpleContextMenu from "vue-3-simple-context-menu";
import { placeholder, tsImportEqualsDeclaration } from "@babel/types";
import { render } from "vue";


var cos_json;


export default {
  name: "View",
  data() {
    return {
      gLinks: [],
      gAuthors: [], //communityに入っているユーザ
      gAuthorsTable: {}, //communityに入っているユーザ
      gViewsTable: {},
      gCurrentView: "",
      viewId: "",
      linkingStyle: null, //チャットと紐付けされているものを光らせるcss
      activeStyle: null,
      hiddenName: false,
      hiddenLink: false,

      modal: { target: null, resizeW: null, resizeH: null },
      lockAspectRatio: false,
      contextmenu: [],
      zindexStyle: null,
      containslinkdic: null,
      links_containslinkdic: null,
      currentTime: null,
      gNewNotes: [],
      gNotes: [],
      gChats: [],
      selectedChatId: null,
      imgEnlarge: null,
      gSyncMode: true,
      input_z:1,
      selectedTarget: '', // 選択された基準を保持するデータ
      targets: [ // プルダウンに表示する基準のリスト
        { id: 1, name: '鮮度（順番）' },
        { id: 2, name: '鮮度（実時間）' },
        { id: 3, name: '内容量' },
        { id: 4, name: '閲覧数' },
        { id: 5, name: '未閲覧数' },
        { id: 6, name: 'egoView' },
        { id: 7, name: 'cos類似度' },
      ]
    };
  },
  props: {
    id: String,
    gKFdata: null
  },
  components: {
    noteVue,
    chatVue,
    uploadAttachment,
    iframeVue,
    searchVue,
    KBDeXVue,
    settingVue,
    chotVue,
    Vue3DraggableResizable,
    DraggableContainer,
    VueSimpleContextMenuView: VueSimpleContextMenu,
    CreateView
  },
  async created() {
    this.currentTime = new Date();
    this.currentTime.setMinutes(this.currentTime.getMinutes() - 3)
    this.currentTime = this.currentTime.getTime()
    setInterval(() => {
      this.currentTime = new Date();
      this.currentTime.setMinutes(this.currentTime.getMinutes() - 3)
      this.currentTime = this.currentTime.getTime()
    }, 10000);
  },
  async mounted() {//ページが読み込まれたら発火する
    var self = this;

    $('#input_z').on('change', async function() {
      await self.ChangeSize();
    });
    $('#target').on('change', async function() {
      await self.ChangeSize();
    });

    kf6.token = window.sessionStorage.getItem("token");
    kf6.baseURL = window.sessionStorage.getItem("kfp.server");
    kf6.communityId = window.sessionStorage.getItem("communityId");
    kf6.authorId = window.sessionStorage.getItem("authorId");

    this.viewId = this.id;

    this.gKFdata.community = await kf6.getCommunity();
    this.gKFdata.communityName = this.gKFdata.community.title;
    this.gKFdata.context = await kf6.getObject(this.gKFdata.community.rootContextId);

    this.gCurrentView = await kf6.getObject(this.viewId);
    this.gKFdata.currentView = this.gCurrentView;
    this.gKFdata.viewName = this.gCurrentView.title;
    this.gKFdata.user = await kf6.getMeAsAuthor();
    this.gKFdata.openSetting = function () {
      self.$refs.settingview.openWindow();
    };
    this.gKFdata.userName = `${this.gKFdata.user.firstName} ${this.gKFdata.user.lastName}`;

    await this.loadAuthors();

    await this.getBuildson();//Buildsonを取得する
    this.containslinkdic = [];
    this.links_containslinkdic = [];


    // cosine類似度のデータを、jsonファイルから取ってきて変数cos_jsonに代入
    // function reqListener() {
    //   cos_json = this.response;
    //   cos_json = JSON.parse(cos_json);
    // }
    // const req = new XMLHttpRequest();
    // req.open("GET", "http://localhost:8000/hagino_cos.json");
    // req.send();
    // req.addEventListener("load", reqListener);
    cos_json = hagino_cos;

    //ノートの表示
    var links = await kf6.getLinksFrom(this.viewId);
    links = links.filter(function (each) {
      return each.type === "contains" && each._to.status === "active" && each._to.type !== "Chat";
    });
    await this.createReadFlag(links); //readFlagを取得する
    for (var i = 0; i <= links.length - 1; i++) {
      var each = links[i];
      await this.createLinkList(each)
      var result = this.buildsonlinks.find(l => l.from === each.to); //buildsonかどうかを判定する
      if (result) {
        //buildsonだった場合
        this.containslinkdic.push(result);
      }
      var result2 = this.buildsonlinks.find(l => l.from === each.to || l.to === each.to); //buildson元・先の少なくともどちらか一方に該当するのか
      if (result2) {
        this.links_containslinkdic.push(each);
      }
    }
    this.gKFdata.linksOnView = this.gLinks;//temporary

  
    this.$nextTick(() => {
      this.createAllLineLeader();//builsonの線を作る
      //svgの幅を変更
      var svgList = document.getElementsByClassName("Drawing")
      if (svgList) {
        for (var i = 0; i <= svgList.length - 1; i++) {
          var svg = svgList[i].getElementsByTagName("svg")[0]
          var width = svg.getAttribute("width")
          var height = svg.getAttribute("height")
          svg.setAttribute("width", "100%")
          svg.setAttribute("height", "100%")
          svg.setAttribute("viewBox", "0 0 " + width + " " + height);
        }
      }
    });

    window.scrollTo(0, 0);//画面表示したときに1番上に表示する

    //view画面addEventListener制御
    const parent = document.getElementById('view-parent');
    parent.addEventListener('contextmenu', e => {
      e.preventDefault();
    }, false);
    parent.addEventListener('drop', e => {
      e.preventDefault()
    }, false);
    parent.addEventListener('dragover', e => {
      e.preventDefault()
    }, false);
    if (!kf6.$socket) {
      kf6.$socket = io(kf6.baseURL,
        {
          path: '/socket.io-client',
          withCredentials: true
        }
      );
    }

    //socketio関連
    kf6.$socket.emit('subscribe', `linkfrom:${this.viewId}`);

    //var self = this;
    kf6.$socket.on(`link:save`, async function (changedlink) {
      var link = self.findLink(changedlink._id);
      if (link) {
        link.data = changedlink.data;
        link._to.title = changedlink._to.title
        self.$nextTick(() => {
          self.createThisLineLeader(link.to)
        })
      } else if (changedlink && changedlink.type === 'contains' && changedlink._to.status === 'active') {
        self.createLinkList(changedlink);
        self.gKFdata.linksOnView = self.gLinks;//temporary

        var result = await kf6.getLinksFrom(changedlink.to)
        result = result[0]
        if (result) {
          self.containslinkdic.push(result);
        }
        self.$nextTick(() => {
          self.createThisLineLeader(changedlink.to)
        })
      }
    });
    kf6.$socket.on(`link:remove`, function (changedlink) {
      self.removeLink(changedlink)
    });
  },
  async beforeDestroy() {
    kf6.$socket.emit('unsubscribe', `linkfrom:${this.viewId}`);
    console.log('destroy');
  },
  beforeRouteLeave(to, from, next) {//画面が戻るときに線を消す
    //console.log('route leave');
    this.removeLine();
    next();
  },
  watch: {
    //view内のviewだったときリロード操作
    $route() {
      location.reload();
    },
  },
  methods: {
    ChangeSize: async function () {
      // console.log('【containslinkdic】',this.containslinkdic);
      var selectedTarget = this.selectedTarget;
       if(selectedTarget === "鮮度（順番）"){
         this.button_ChangeSize_TimeSort_ver1();
       }else if(selectedTarget === "鮮度（実時間）"){
         this.button_ChangeSize_TimeSort_ver2();
       }else if(selectedTarget === "内容量"){
         this.button_ChangeSize_NoteLength();
       }else if(selectedTarget === "閲覧数"){
         this.button_ChangeSize_ReadCount();
       }else if(selectedTarget === "未閲覧数"){
         this.button_ChangeSize_ReadCount_reverse();
       }else if(selectedTarget === "egoView"){
         this.button_ChangeSize_egoView();
       }else if(selectedTarget === "cos類似度"){
         this.button_ChangeSize_cosSim();
       }
    },
    button_ChangeSize_TimeSort_ver1: async function () {
      var z = this.input_z; //視野のスケールを表現
      var view = this.gCurrentView;
      var links = this.gLinks;
      // console.log('gLinks',links)

      var Scale=0.5;  //始まり
      var Scale_final=0.5;
      var range = 1 / links.length; //１ノートごとに増える量
      links[0].Scale = Scale;
      for (var i = 0; i < links.length; i ++){
        var each = links[i]
        Scale += range;
        Scale_final = Scale * z;
        if (Scale_final > 2.5){
          // Scale_final = 0;
          each.hiddenLink = true;
          
          // console.log(this.containslinkdic);

        }else if(Scale_final <= 2.5){
          each.hiddenLink = false;
        }
        each.Scale = Scale_final;
      }
      setTimeout(this.button_Direction_Cleaner,10);
    },

    button_ChangeSize_TimeSort_ver2: async function () {
      var z = this.input_z; //視野のスケールを表現
      var view = this.gCurrentView;
      var links = this.gLinks;
      // console.log('元のgLinks',links)

      var created_List = [];
      for (var i = 0; i < links.length; i ++){
        var created = links[i]._to.time[1]
        created_List.push(created)
      }
      var minTime = created_List[0] //最古
      var maxTime = created_List[created_List.length - 1] //最近

      var range  = maxTime - minTime;
      var perTime = (40-0.5) / range;
      var Scale = 0.5;  //始まり
      var Scale_final;
      links[0].Scale = Scale;
      for (var i = 1; i < links.length - 1; i ++){
        var each = links[i];
        Scale += (created_List[i] - created_List[i-1] ) * perTime
        Scale_final = Scale * z / 20;
        if (Scale_final > 3){
          each.hiddenLink = true;
        }else if(Scale_final <= 3){
          each.hiddenLink = false;
        }
        each.Scale = Scale_final;
      }
      setTimeout(this.button_Direction_Cleaner,10);
    },

    button_ChangeSize_NoteLength: async function () {
      var z = this.input_z; //視野のスケールを表現
      var view = this.gCurrentView;
      var links = this.gLinks;
      console.log('元のgLinks',links)
      var objects =await kf6.getObjects({type:'Note', viewIds:[view._id],  pagesize:1000});
      // console.log(objects)

      for (var i = 0; i < links.length; i ++){
        var link = links[i]
        var to_id = link.to
        for (var j = 0; j < objects.length; j ++){
          var object = objects[j];
          var text4search;
          if (object._id === to_id){
            text4search = object.text4search;
          }
          // console.log(text4search)
          var len = text4search.length;
          var Scale = 0.5;
          var each_Scale = Scale + (len / 300) * z
        }
          if (each_Scale > 3){
          link.hiddenLink = true;
        }else if(each_Scale <= 3){
          link.hiddenLink = false;
        }
          link.Scale = each_Scale ;
        
      }
      setTimeout(this.button_Direction_Cleaner,10);
    },

    button_ChangeSize_ReadCount_reverse: async function () {
      var z = this.input_z; //視野のスケールを表現
      var view = this.gCurrentView;
      var view_id = view._id;
      // console.log('【このViewのViewID】',view_id)

      var objects =await kf6.getObjects({type:'Note', viewIds:[view_id],  pagesize:1000});
      console.log('【Objects】',objects)
      var Scale_dic = {};

      for (var i = 0; i< objects.length; i ++){
        var object = objects[i];
        var to_id = object._id;
        var links = await kf6.getLinks({type:"read", viewId:[view_id], to:[to_id]}); 
        var read_count = links.length
        Scale_dic[to_id] =  0.3 + 2 / read_count;
      }

      var glinks = this.gLinks;
      for (var i = 0; i< glinks.length; i ++){
        var each = glinks[i];
        var id = each.to;
        if (Scale_dic[id] * z > 3){
          each.hiddenLink = true;
        }else if(Scale_dic[id] * z <= 3){
          each.hiddenLink = false;
        }
        each.Scale = Number(Scale_dic[id] * z)
      }
      setTimeout(this.button_Direction_Cleaner,10);
    },

    button_ChangeSize_ReadCount: async function () {
      var z = this.input_z; //視野のスケールを表現
      var view = this.gCurrentView;
      var view_id = view._id;
      // console.log('【このViewのViewID】',view_id)

      var objects =await kf6.getObjects({type:'Note', viewIds:[view_id],  pagesize:1000});
      // console.log('【Objects】',objects)
      var Scale_dic = {};

      for (var i = 0; i< objects.length; i ++){
        var object = objects[i];
        var to_id = object._id;
        var links = await kf6.getLinks({type:"read", viewId:[view_id], to:[to_id]}); 
        var read_count = links.length
        Scale_dic[to_id] =  0.3 + read_count / 6;
      }

      var glinks = this.gLinks;
      for (var i = 0; i< glinks.length; i ++){
        var each = glinks[i];
        var id = each.to;
        if (Scale_dic[id] * z > 3){
          each.hiddenLink = true;
        }else if(Scale_dic[id] * z <= 3){
          each.hiddenLink = false;
        }
        each.Scale = Number(Scale_dic[id] * z)
      }
      setTimeout(this.button_Direction_Cleaner,10);
    },

    button_ChangeSize_egoView: async function () {
      var z = this.input_z; //視野のスケールを表現
      var me = await kf6.getMeAsAuthor();
      var my_lastName = me.lastName
      var my_firstName = me.firstName
      var links = this.gLinks;
      // console.log('【glinks】',links)

      for (var i = 0; i < links.length; i ++){
        var each = links[i];
        // 自分のNoteなら1.5に、それ以外は0.8にScaleを設定。
        if (each._to.author.firstName === my_firstName && each._to.author.lastName === my_lastName){
          each.Scale = 1.5 * z;
        }else{
          each.Scale = 0.8 * z;
        }
        if (each.Scale > 3){
          each.hiddenLink = true;
        }else if(each.Scale <= 3){
          each.hiddenLink = false;
        }
      }
      setTimeout(this.button_Direction_Cleaner,10);
    },

    button_ChangeSize_cosSim: async function () {
      var z = this.input_z; //視野のスケールを表現

      var links = this.gLinks;

      for (var i = 0; i < links.length; i ++){
        var link = links[i];

        var cosSim = link.cosSim;
        
        if(!cosSim){
          link.Scale = 1;
        }else if (cosSim == 999){
          link.Scale = 0.5;
        }else{

          //押したノートが最も大きくなるように（z＝2.5-3くらいが比較的ちょうど良いけど、見やすくはない）
          link.Scale = (cosSim * 0.5 + 0.6) * z * 0.4

          //押したノートが最も小さくなるように（z＝3.5くらいがちょうど良い）
          // link.Scale = ((cosSim * 0.5) * (-1) + 0.6) * z * 0.8;
          //(*0.5で、cosSimの範囲を約-0.5〜0.5に)」
          //(*(-1)で、cosSimが小さいノートほど、ノートのサイズが大きくなるように)」
          //(+0.6で、cosSimの範囲を、約0.1〜1.1に調整)
          //(*0.8で、微調整（cosSimの範囲は、約0.08〜0.88になる)）（z=1のとき、押したノート自身だけ、0.07999...になる）
        }
      }
      setTimeout(this.button_Direction_Cleaner,10);
    },

    button_Direction_Cleaner: async function () {
      this.removeLine();
      this.createAllLineLeader();
    },
    findLink(linkId) {
      var links = this.gLinks;
      for (var i = 0; i < links.length; i++) {
        var link = links[i];
        if (link._id === linkId) {
          return link;
        }
      }
      return null;
    },
    createLinkList: async function (each) {
      if (each._to.type === "Attachment" || each._to.type === "Drawing") {
        //画像だった場合urlを取得する
        each._to["url"] = await this.getAttachment(each);
      }
      var imgsrc = await this.getIcon(each); //iconを取得する
      each._to["icon"] = imgsrc;
      var eachAuthor = this.gAuthorsTable[each._to.authors[0]]; //作成者を取得する
      each._to["author"] = {
        _id: eachAuthor._id,
        firstName: eachAuthor.firstName,
        lastName: eachAuthor.lastName,
      };
      each._to["time"] = await this.getTimeString(each._to.modified); //時間を取得する
      this.gLinks.push(each);
    },
    removeLine() {
      //view画面からcommunityへ戻るときに全ての矢印を削除する
      for (var i = 0; i <= this.containslinkdic.length - 1; i++) {
        var link = this.containslinkdic[i];
        var to = "line/" + link.to;
        var from = "line/" + link.from;
        if (this.$refs[to] === undefined || this.$refs[from] === undefined) {
          //chatだった場合(view内にオブジェクトが表示されていない時)
          continue;
        }
        if (link.line) {
          //矢印を消す
          var line = link.line;
          line.remove();
        }
      }
    },
    getAttachment: async function (each) {
      //画像URLを取得
      var obj = await kf6.getObject(each.to);
      if (obj.type === "Drawing") {
        each.data.width = Number(each.data.width)
        each.data.height = Number(each.data.height)
        var url = obj.data.svg
        return url;
      } else {
        var type = obj.data.type;
        type = type.split("/");
        each._to["attachment"] = type[0];
        if (type[0] === "image") {
          return this.createImageLink(obj);
        }
      }
    },
    createImageLink: function (obj) {
      //attachment画像のタグを作る
      var servername = kf6.baseURL;
      var url = servername.slice(0, -1) + obj.data.url;
      return `<object style="width:100%" data="${url}">
                <iframe style="width:100%; height:100%; !important;" src="${url}">
                'The browser does not support this object.'
                </iframe>
                </object>`;
    },
    createReadFlag: async function (links) {
      //readFlagをつける
      var readings = await kf6.getReadStatusForView(this.viewId);
      for (var i = 0; i < links.length; i++) {
        var link = links[i];
        for (var j = 0; j < readings.length; j++) {
          var reading = readings[j];
          if (reading.to === link.to) {
            if (link._to.type == "Note" || link._to.type == "Chat") {
              link._to["readFlag"] = true;
              break;
            }
          }
        }
      }
    },
    getIcon: function (each) {
      //iconを取得する
      if (each._to.type === "Note") {
        if (each._to.readFlag) {
          return noteReadImage;
        } else {
          return noteUnreadImage;
        }
      } else if (each._to.type === "View") {
        return viewImage;
      } else if (each._to.type === "Attachment") {
        return attachmentImage;
      } else if (each._to.type === "Drawing") {
        return drawingImage;
      } else if (each._to.type === "ChotSession") {
        return chotImage;
      }
    },
    insertCosSim: function (noteID) {
      //noteIDのノートとその他のノートとのcosine類似度を、それぞれのlinkに入れる関数

      var linksForCos = this.gLinks;

      if(!cos_json[noteID]){//noteIDのノートが、cosine類似度をまだ求めていないノートの場合（cos類似度が表示されている場合にそれを消すため）
        for(var i=0; i<linksForCos.length; i++){
          var each = linksForCos[i];
          each.cosSim = undefined;
        }
        return;
      }
      for(var i=0; i<linksForCos.length; i++){
        var each = linksForCos[i];
        var cosSim = cos_json[noteID][each.to];//noteIDのノートとその他のあるノート(each.to）とのcosine類似度
        if(cosSim){
          each.cosSim = cosSim;
        }else{ //cosine類似度をまだ求めていないノートの場合
          each.cosSim = 999;
        }
      }
    },
    // calc_distance: function (x_from, y_from, coordinates) {//引数は、任意の一つのノートの座標
    //   //view内の全てのノートについて、ある任意の一つのノートとの距離を求める機能
    //   const distance_XandY = coordinates.map((v) => {
    //     return { x: Math.abs(v.x - x_from), y: Math.abs(v.y - y_from) }
    //   })
    //   distance = distance_XandY.map((v) => 
    //     Math.sqrt(Math.pow(v.x, 2) + Math.pow(v.y, 2))
    //   )
    // },
    // extract_limited: function(limit_length){//「limit_length」はdistanceの閾値
    //   //「一定距離内」にあるノートについてのデータ取り出し機能（「一定距離内」機能）
    //   //distanceが、閾値以下のノートのみの、distanceとcosSimを取り出す
    //   for(var i=0; i<distance.length; i++){
    //     if(distance[i] <= limit_length){
    //       if((distance[i] != 0) && cosSim[i]!==999){ //押されたノート自身は除外,cosine類似度をまだ求めていないノートは除外
    //         distance_limited.push(distance[i]);
    //         cosSim_limited.push(cosSim[i]);
    //       }
    //     }
    //   }
    // },
    // extract_top: function (limit_top){//「limit_top」は、近距離上位いくつまでのノートを取り出すか
    //   //「近距離上位」ノートについてのデータ取り出し機能（「近距離上位」機能）
    //   //distanceが、小さいtop〇〇のノートのみの、distanceとcosSimを取り出す

    //   var distance_copy = distance;

    //   const aryMin = function (a, b) {return Math.min(a, b);}
    //   while(distance_top.length < limit_top){//limit_top個のノートを取り出す

    //     var min = distance_copy.reduce(aryMin);
    //     var minIndex = distance_copy.indexOf(min);
    //     distance_copy[minIndex] = 999999;//次のループで最小値に該当しないようにする

    //     if(min==999999){//distance_copyの全ての要素を調べ終わった場合
    //       break;
    //     }

    //     if(min==0 || cosSim[minIndex]==999){//押されたノート自身は除外,cosine類似度をまだ求めていないノートは除外
    //       continue;
    //     }

    //     distance_top.push(min);
    //     cosSim_d_top.push(cosSim[minIndex]);
    //   }
    // },
    // calc_r_cos_distance: function(x,y){
    //   //二つの配列の相関係数を求める関数

    //   //x,yの配列の長さ（x,yの配列の長さが等しいことを想定）
    //   const n = x.length

    //   const xAverage = x.reduce((a, b) => a + b)/n
    //   const yAverage = y.reduce((a, b) => a + b)/n
           
    //   //x*yの平均を求める
    //   var xySum = 0
    //   for(var i=0; i<n; i++){
    //     xySum += x[i] * y[i]
    //   }
    //   const xyAverage = xySum/n //x*yの平均
            
    //   //x,yの標準偏差を求める
    //   var deviationX = 0
    //   var deviationY = 0
    //   for(var i=0; i<n; i++){
    //     deviationX += Math.pow(x[i] - xAverage, 2)
    //     deviationY += Math.pow(y[i] - yAverage, 2)
    //   }
    //   const deviationXAverage = deviationX/n
    //   const deviationYAverage = deviationY/n
    //   const sigmaX = Math.sqrt(deviationXAverage) //xの標準偏差
    //   const sigmaY = Math.sqrt(deviationYAverage) //yの標準偏差

    //   const r = (xyAverage - xAverage * yAverage) / (sigmaX * sigmaY) //相関係数
    //   return r
    // },
    // execute: function(){
    //   // 押されたノートについてorある任意のノートについて相関係数を求める

    //   this.extract_limited(500);//引数によっては、エラーが出て動かない場合がある
    //   this.extract_top(20);
    // },
    // calc_r_ave: function(linksForCos,coordinates){
    //   var r_limited_sum = 0;
    //   var r_top_sum = 0;
    //   var repeat_num = 0;

    //   for(var i=0; i<linksForCos.length; i++){
    //     var each = linksForCos[i];

    //     if(!cos_json[each.to]){//cosine類似度をまだ求めていないノートの場合、このループをスキップ
    //       continue;
    //     }
    //     repeat_num += 1;

    //     this.insertCosSim(each.to,linksForCos);
    //     this.calc_distance(each.data.x,each.data.y,coordinates);
    //     cosSim = linksForCos.map((v) => v.cosSim);
    //     distance_limited = [];
    //     cosSim_limited = [];
    //     distance_top = [];
    //     cosSim_d_top = [];
    //     this.execute();

    //     r_limited_sum += this.calc_r_cos_distance(distance_limited, cosSim_limited);
    //     r_top_sum += this.calc_r_cos_distance(distance_top, cosSim_d_top);
    //   }

    //   var average_r_limited = r_limited_sum/repeat_num;
    //   var average_r_top = r_top_sum/repeat_num;
    // },
    // r_cos_distance: function(link){
    //   //ノート間のcosine類似度と距離との相関係数を求める

    //   var linksForCos = this.gLinks;

    //   //view内の全てのノートについて、座標を取得
    //   // const coordinates = linksForCos.map((v) => {
    //   //   return { x: v.data.x, y: v.data.y }
    //   // })

    //   //相関係数関連の変数
    //   //var distance;
    //   //var distance_limited = []
    //   //var cosSim_limited = [];
    //   //var distance_top = [];
    //   //var cosSim_d_top = [];

    //   //view内の全てのノートについて、押されたノートとの（現在は押されたノートに限定しているが、変える）cosine類似度を取り出す
    //   // var cosSim = linksForCos.map((v) => v.cosSim);

    //   //this.calc_r_ave(linksForCos,coordinates);
    //   //すぐに直す
    // },
    objectDoubleclicked: async function (link) {
      if (link._to.type === "Note") {//ノートが押されたとき

        if (this.$refs["note/" + link.to]) {
          this.$refs["note/" + link.to][0].readNote();
          this.activeStyle = null
          return;
        }
        await this.gNotes.push(link)
        this.$refs["note/" + link.to][0].readNote();
        if (link._to.type === "Note") {
          link._to.icon = noteReadImage;
        }
      }
      else if (link._to.type === "Drawing") {//Drawing
        return
      }
      else if (link._to.type === "View") {//View
        this.$router.push({
          name: "view",
          params: { id: link.to },
        });
      } else if (link._to.type === "ChotSession") {
        this.$refs.chotview.openWindow(link.to);
      } else {
        console.error(`${link._to.type}には対応していません`);
      }
      this.activeStyle = null
    },
    loadAuthors: async function () {
      var authors = await kf6.getAuthors();
      this.gAuthors = authors;
      authors.forEach((author) => {
        this.gAuthorsTable[author._id] = author;
      });
      this.gKFdata.authors = authors;
      this.gKFdata.authorsTable = this.gAuthorsTable;
    },
    getTimeString: function (time) {
      //時間を取得
      var d = new Date(time);
      return [d.toLocaleString(), d.getTime()];
    },
    viewToolbarPressed: async function (...tools) {//左のツールバーが押されたときに発火する
      var tool = tools[0];
      if (tool === "note") {
        //新規ノート作成ボタンが押されたとき
        await this.gNewNotes.push("newNote")
        var index = this.gNewNotes.length - 1
        this.$refs["newNote/" + index][0].createNote(); //NoteVue内のcreateNoteを実行
      } else if (tool === "attachment") {
        this.$refs.upload.openWindow();
      } else if (tool === "hideName") {
        this.hiddenName = !this.hiddenName;
      } else if (tool === "drawing") {
        this.$refs.iframetool.openWindow("kakeru");
      } else if (tool === "view") {
        this.$refs.createview.openWindow();
      } else if (tool === "workspace") {
        var author = await kf6.getMeAsAuthor();
        if (author.data) {
          var myWorkspace = await kf6.getObject(author.data.workspaces[0])
          window.open(`./view${myWorkspace._id}`, 'subwin', 'width=700,height=300')
        } else {
          alert('workspace not found.');
        }
      } else if (tool === "search") {
        this.$refs.searchview.openWindow();
      } else if (tool === "kbdex") {
        this.$refs.kbdexview.openWindow();
      } else if (tool === "chot") {
        kf6.createChot(this.gKFdata.currentView, window);
        //this.$refs.chotview.openWindow();
      } else if (tool === "exit") {
        //this.removeLine();
        this.$router.push({ name: "community" });
      }
    },
    getBuildson: async function () {
      //view内のビルズオンのノートを取得する
      this.buildsonlinks = await kf6.getLinks({ type: "buildson" });
      this.buildsonlinks = this.buildsonlinks.filter(function (each) {
        return (
          each._from.status !== "unsaved" &&
          each.communityId === kf6.communityId
        );
      });
    },
    createAllLineLeader: function () {
      //画面表示時にview内の矢印を全て描画する
      for (var i = 0; i <= this.containslinkdic.length - 1; i++) {
        var link = this.containslinkdic[i];
        var to = "line/" + link.to;
        var from = "line/" + link.from;
        if (!this.$refs[from] || !this.$refs[to]) {
          //既にbuildson先が削除されている場合、lineを描画しない
          continue;
        }
        var line = new LeaderLine(this.$refs[from][0], this.$refs[to][0], {
          color: "#41B983",
          size: 1.5,
          path: "straight",
        });
        link["line"] = line;
      }
    },
    createThisLineLeader: function (linkId) {
      //任意の矢印を表示する
      for (var i = 0; i <= this.containslinkdic.length - 1; i++) {
        var link = this.containslinkdic[i];
        if (link.to === linkId || link.from === linkId) {
          var to = "line/" + link.to;
          var from = "line/" + link.from;
          if (!this.$refs[from] || !this.$refs[to]) {
            continue;
          } else if (this.$refs[from].length === 0 || this.$refs[to].length === 0) {
            continue;
          }
          if (link.line) {
            //既に表示されていたら削除してから再描画
            var line = link.line;
            line.remove();
          }
          line = new LeaderLine(this.$refs[from][0], this.$refs[to][0], {
            color: "#41B983",
            size: 1.5,
            path: "straight",
          });
          link["line"] = line;
        }
      }
    },
    mouseup: async function (event, link) {
      //マウスが離れた場所でオブジェクトの位置を保存する
      if (this.gChats) {
        for (var i = 0; i <= this.gChats.length - 1; i++) {
          var chat = this.gChats[i]
          var ref = this.$refs['chat/' + chat.to][0]
          if (ref.isDisplay) {
            var dragTarget = this.$refs['line/' + link.to][0]
            var dropArea = document.getElementsByClassName('chat')[i]
            //draggable-resizableのライブラリの関係でドラッグドロップが指定できないため,
            //チャットとドラッグするものが重なっていた場合、チャットと紐付けるように判定する
            var result = this.hoverCheck(dropArea, dragTarget)
            if (result) {
              var confirmation = window.confirm(
                link._to.title + " とchacoを紐づけますか"
              );
              if (!confirmation) {
                result = "";
                return;
              } else {
                this.$refs['chat/' + chat.to][0].preview = link
              }
              break;
            }
          }
        }
      }
      if (!link) {
        return;
      } else if (this.screenX === event.screenX && this.screenY === event.screenY) {//ダブルクリック時にずれないように
        return;
      } else if (result) {
        var elm = dropArea.getBoundingClientRect() //チャットにドロップした場合チャットの横に画像を表示する
        link.data.x = elm.width + elm.left - 30
        link.data.y += 1
      } else {
        //view画面から出たら画面内に戻す
        if (link.data.x <= 0) {
          link.data.x = 0
        }
        if (link.data.y <= 0) {
          link.data.y = 0
        }
        window.dragend = null;
      }
      this.$nextTick(async () => {
        this.createThisLineLeader(link.to);
        await kf6.saveLink(link);
      });
    },
    hoverCheck: function (elm1, elm2) {
      var d1 = elm1.getBoundingClientRect()
      var d2 = elm2.getBoundingClientRect()
      return !(
        d1.top > d2.bottom ||
        d1.right < d2.left ||
        d1.bottom < d2.top ||
        d1.left > d2.right
      );

    },
    contextMenuPressed: async function (event) {//contextmenuが押されたら
      var slug = event.option.slug;
      var link = event.item;
      if (slug === "delete") {
        this.deleteButtonPressed(link);
      } else if (slug === "copyObject") {
        this.copyTarget = link
        alert('オブジェクトをコピーしました.貼り付けたいオブジェクトを選択してください.')
      } else if (slug === "resize") {
        this.modal.target = link;
        var placeholder = await document.getElementById("modal-contents-width")
        placeholder.focus()
      } else if (slug === "displayChat") {
        if (this.$refs["chat/" + link.to]) {
          await this.$refs["chat/" + link.to][0].chatReload()
          await this.$refs["chat/" + link.to][0].openWindow()
        } else {
          await this.gChats.push(link)
          await this.$refs["chat/" + link.to][0].chatReload()
          await this.$refs["chat/" + link.to][0].openWindow()
        }
      } else if (slug === "pasteObject") {
        if (!this.copyTarget) {
          alert("先にオブジェクトをコピーしてください")
        } else {
          await this.$refs["chat/" + link.to][0].chatReload()
          await this.$refs["chat/" + link.to][0].openWindow(this.copyTarget)
          this.copyTarget = ""
        }
      } else if (slug === "buildson") {
        link = await kf6.getObject(link.to)
        await this.gNewNotes.push("newNote")
        var index = this.gNewNotes.length - 1
        this.$refs["newNote/" + index][0].createNote(link)
      } else if (slug === "openNote") {
        if (this.$refs["note/" + link.to]) {
          this.$refs["note/" + link.to][0].readNote();
        } else {
          await this.gNotes.push(link)
          this.$refs["note/" + link.to][0].readNote();
          if (link._to.type === "Note") {
            link._to.icon = noteReadImage;
          }
        }

      } else if (slug === "download") {
        if (this.$refs["note/" + link.to]) {
          this.$refs["note/" + link.to][0].downloadItem();
        } else {
          await this.gNotes.push(link)
          this.$refs["note/" + link.to][0].downloadItem();
          if (link._to.type === "Note") {
            link._to.icon = noteReadImage;
          }
        }

      } else if (slug === "cos") {
        this.insertCosSim(link.to);
        //this.displayNotes_cos(link);
      } else if (slug === "別のbuildsonTree") {
        this.displayNotes_buildsonTrees(link);
      }
      this.activeStyle = null
    },
    displayNotes_cos: async function (link) {
      //cos類似度を表示したら，クリックしたノートと，cosが0.4以下の他者のノートだけを，表示．
      var gLinks = this.gLinks;
      var me = await kf6.getMeAsAuthor();
      gLinks = gLinks.filter(function (each) {
        return each === link || (each.cosSim < 0.4 && each._to.author._id !== me._id);
      });
      this.gLinks = gLinks;
    },
    displayNotes_buildsonTrees: async function (link) {
      //buildsonTreeに含まれるノート（this.links_containslinkdic）のうち，クリックしたノート（link.to）が含まれるbuildsonTree（分岐なし）を表示しないように．
      var noteId = link.to;
      var buildsonTree = true;
      while (buildsonTree == true){
        var buildsonlink = this.buildsonlinks.find(l => l.from === noteId);//noteIdのノートがfromであるbuildsonリンクを探す
        if(buildsonlink){
          this.links_containslinkdic = this.links_containslinkdic.filter(function (each) {//「this.links_containslinkdic」のノートたちから，「buildsonlink」の先にあるノート（buildsonlink.to）を取り除く．
            return each.to !== buildsonlink.to;
          });
          noteId = buildsonlink.to;
        }else{
          buildsonTree = false;
        }
      }
      this.gLinks = this.links_containslinkdic;
    },
    deleteButtonPressed: async function (link) {
      //deleteボタンが押されたら
      var confirmation = window.confirm(
        "Are you sure to delete the selected " + link._to.title + " object(s)?"
      );
      if (!confirmation) {
        return;
      } else {
        await kf6.deleteLink(link)
        //deleteLinkが成功したら実行される
      }
    },
    removeLink: function (link) {
      var linkId = link.to
      for (var i = 0; i <= this.containslinkdic.length - 1; i++) {
        var buildsonlink = this.containslinkdic[i];
        if (buildsonlink.to === linkId || buildsonlink.from === linkId) {
          var to = "line/" + buildsonlink.to;
          var from = "line/" + buildsonlink.from;
          if (!this.$refs[from] || !this.$refs[to]) {
            //もしbuildson先のnoteが削除されてしまっていたら、以下の処理を行わない
            continue;
          } else if (this.$refs[from].length === 0 || this.$refs[to].length === 0) {
            continue;
          }
          if (buildsonlink.line) {
            //矢印を消す
            var line = buildsonlink.line;
            line.remove();
          }
        }
      }
      //gLinkのリストから削除するリンクを見つけて削除
      var index = this.gLinks.findIndex((e) => {
        return e._id === link._id
      })
      this.gLinks.splice(index, 1); //配列から削除したノートを消す
      this.activeStyle = null;
    },
    chatLinking: function (toId) {
      //任意のノートを光らせるcssを追加
      this.linkingStyle = toId;
    },
    modalEvent: async function () {
      //画像変更
      var link = this.modal.target
      link.data.width = this.modal.resizeW;
      link.data.height = this.modal.resizeH;
      await kf6.saveLink(link);
      this.modal.target = null;

    },
    dragEnter: function (event) {
    },
    drop: async function (event) {
      //view画面にオブジェクトがドロップされたら
      if (event.dataTransfer.getData('text/html')) {
        //他のviewからオブジェクトがドロップされたら
        var dropData = event.dataTransfer.getData('text/html')
        var dropId = dropData.split('id="')[1].split('"')[0]
        var object = await kf6.getObject(dropId)
        var data = {
          x: event.clientX,
          y: event.clientY,

        }
        var type = object.data.type.split("/")[0];
        if (type === "image") {
          data["height"] = 200
          data["width"] = 200
          data["showInPlace"] = true
        }
        this.$nextTick(() => {
          kf6.createLink(this.viewId, dropId, "contains", data)
        })
        return
      } else if (event.dataTransfer.getData('text')) {
        //チャットからドロップされたら
        var dropData = event.dataTransfer.getData('text')

        var confirmation = window.confirm(
          "チャットの内容から新規ノートを作成しますか？"
        );
        if (!confirmation) {
          return
        } else {
          var buildsonOrigin = await kf6.getObject(this.selectedChatId)
          var newData = { title: "", data: { body: "" } };
          try {//ドロップされたものがJSON形式の配列かどうか
            dropData = JSON.parse(dropData)
            newData.data.body = dropData.join('<br>')//ドロップした内容をまとめてビルズオンにする

          } catch {//１コメントのみだった場合
            newData.data.body = dropData
          }
          await this.gNewNotes.push("newNote")
          var index = this.gNewNotes.length - 1
          this.$refs["newNote/" + index][0].createNote(buildsonOrigin, newData);
          this.$refs["newNote/" + index][0].fromChat = true;
          this.$refs["newNote/" + index][0].timeOfDropTarget = dropData.slice(-1)[0].modified;
        }
        return;
      } else if (event.dataTransfer.files.length) {
        //ドロップされたものがAttachmentだった場合
        var files = event.dataTransfer.files;
        this.$refs.upload.selectedFile(files);

      }
    },
    showContextMenu: function (event, item) {
      this.contextmenu = [{ name: "Builds-on", slug: "buildson" },
      { name: "chaco", slug: "displayChat" },
      { name: "open", slug: "openNote" },
      { name: "cos", slug:"cos"},
      { name: "別のbuildsonTree", slug: "別のbuildsonTree" },
      ]
      if (item._to.author._id === kf6.authorId) {
        this.contextmenu.push({ name: "delete", slug: "delete" })

      }
      if (item._to.type === "Attachment") {
        this.contextmenu.push({ name: "download", slug: "download" })
        this.contextmenu.push({ name: "resize", slug: "resize" })
      }
      this.$refs.vueSimpleContextMenu.showMenu(event, item);
      var menu = document.getElementById('context-menu').getElementsByTagName('ul')[0]
      menu.style.left = event.pageX + "px"
      menu.style.top = event.pageY - 50 + "px"
    },
    saveLink: function (link) {
      kf6.saveLink(link)
    }
  }
};
</script>

<style scoped>
/* view-toolbar css */
.view-toolbar-wrap {
  /* 左側に固定 */
  /* float: left; */
  background-color: #dddddd;
  position: fixed;
  height: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 10;
}

.ScaleSetting{
  padding: 30px;
}

.view-toolbar-button {
  margin: 5px 0;
}

.view-toolbar-button img {
  width: 30px;
}

.view-toolbar-button:hover {
  cursor: pointer;
}

/* view css */
#view-parent {
  width: 2000px;
  height: 2000px;
  position: relative;
  left: 30px;
}

.iwindow-layer {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 3;
  pointer-events: none;
}

.object-style {
  line-height: 0.7em;
  white-space: nowrap;
  border-radius: 5px;
  padding: 3px;
  border: 1px solid #80000000;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 14px;
}

.object-style:hover {
  cursor: pointer;
  text-decoration: underline;
}

span {
  font-size: 10px;
  width: 100%;
  height: 100%;
  line-height: 1;
}

/* note css */
.view-note-style {
  position: fixed;
  z-index: 5;
}

/* clickEvent css */
.display {
  display: block;
}

.active-style {
  border: 1px solid #41b983;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.2);
  z-index: 6 !important;
}

.linking {
  border: 1px solid #e5ff00 !important;
  box-shadow: 0 3px 30px 0 rgba(255, 191, 0, 0.579) !important;
  z-index: 6 !important;
}

/* resizable css */
.resize-wrap {
  position: absolute;
  width: 100%;
}

.resizableHandle {
  position: absolute;
  width: 5px;
  height: 5px;
  border: 1px solid black;
  background-color: #ffffff;
}

/* modal window */
.modal-wrap {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  z-index: 20;
}

.modal-contents {
  position: relative;
  width: 300px;
  height: 100px;
  background-color: #ffffff;
  border: 1px solid #80000000;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.2);
  text-align: center;
  margin: auto;
  line-height: 100px;
}

.modal-contents input {
  width: 50px;
}

.modal-close-button {
  position: absolute;
  top: 0;
  right: 0;
}

/* zindex style */
.zindex {
  z-index: 6 !important;
}

.zindex0 {
  z-index: 0 !important;
}

/* 新しいオブジェクトを赤くする */
.new-object {
  border: 1px solid red;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.2);
}

/* 選択したimg を大きくする */
/* .img-enlarge {
  width: 400px !important;
} */
</style>
